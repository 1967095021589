<template>
  <section class="app-main">
    <div class="container is-fluid is-marginless app-content">
      <div class="columns head is-vcentered">
        <div class="column is-offset-3 is-6 has-text-centered">
          <h3 class="title is-size-3 has-text-weight-normal">Mes services</h3>
        </div>
        <div class="column is-3 is-hidden-mobile">
          <ol class="breadcrumb">
            <li>
              <a href="/#" class="is-active">Home</a>
            </li>
            <li>
              <span class="active">Mes services</span>
            </li>
          </ol>
        </div>
      </div>
      <div class="animated m-b-30">
        <div class="tile is-ancestor">
          <div class="tile is-parent is-success">
            <article class="tile is-child box">
              <div class="has-text-centered m-t-30 m-b-30">
              <p class="subtitle">Statut : <span style="color: #7972ff"> {{ status }} </span></p>
              <p class="subtitle" v-if="volant">Compteur vacations : <span style="color: #7972ff"> {{ mesinfos.counter }} </span></p>
              <div class="subtitle" v-else>
                <p>Services anticipés : <span style="color: #7972ff"> {{ mesinfos.counter }} </span></p>
                <p>Rattrapages : <span style="color: #7972ff"> {{ mesinfos.abcdCounter }} </span></p>
              </div>
              <p class="subtitle" v-if="mesinfos.type!='standard'">{{ team }}</p>
              <p class="subtitle" v-if="mesinfos.type!='standard' && volant">{{ next_service }}</p>
              </div>
            </article>
          </div>
        </div>
      </div>
      <br>
      <div class="animated">
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <section v-if="errorAPI">
                <p
                  class="container tag is-danger is-large"
                >Désolé, information non disponible actuellement. Merci de réessayer plus tard</p>
              </section>

              <section class="recap" v-else>
                <div v-if="loadingAPI">
                  <p class="title box is-size-4 has-text-weight-normal m-b-30">
                    <i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                    <span>Chargement en cours ...</span>
                  </p>
                </div>

               <p class="title">À venir</p>
                <table class="table is-fullwidth is-hoverable is-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Statut</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="service in futureServices" v-bind:key="service.id">
                      <td  class="coll-format">{{formatDate(service.dateBegin)}}</td>
                      <td class="coll-format"><span
                        class="has-text-weight-bold"
                      >{{ state_fr(service.state) }} </span></td>
                      <td  ><span
                        class="icon">
                        <i class="fa fa-lg" :class="getClass(service.state)"></i>
                      </span></td>
                    </tr>
                  </tbody>
                </table>

                <p class="title">Historique</p>
                <table class="table is-fullwidth is-hoverable is-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Statut</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="service in pastServices" v-bind:key="service.id">
                      <td  class="coll-format">{{formatDate(service.dateBegin)}}</td>
                      <td  class="coll-format"><span
                        class="has-text-weight-bold"
                      >{{ state_fr(service.state) }} </span></td>
                      <td  ><span
                        class="icon">
                        <i class="fa fa-lg" :class="getClass(service.state)"></i>
                      </span></td>
                    </tr>
                  </tbody>
                </table>

              </section>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import date from "@/utils/formatDate"
import shiftServices from '@/services/shiftServices'
import { authComputed } from '@/store/helpers.js'
import statusMixin from '@/mixins/statusMixin.js'

export default {
  name: "Services",
  data: function() {
    return {
      services: [],
      loadingAPI: true,
      errorAPI: false,
      mesinfos: "",
      states:
      { 'done': 'Validé' ,
        'open': 'À venir',
        'replacing': 'Remplacement',
        'replaced': 'A été remplacé',
        'absent': 'Absent - 2 rattrapages',
        'excused': 'Absent - 1 rattrapage',
        'cancel': 'Annulé',
        'waiting': 'En congé' },
      volant: ""
    };
  },
  mixins: [date, statusMixin],
  mounted() {
    this.mesinfos = this.$store.getters.getUser
    this.volant = this.mesinfos.currentTeam.includes("volante")
    this.init()
  },

  methods: {
    init: function() {
      shiftServices.fetchMyShifts(this.mesinfos.id, this.$moment().subtract(1, 'year').format("YYYY-MM-DD"))
        .then(
          response =>
            (this.services = response.data.shiftRegistrations.reverse(),this.loadingAPI=false)
        )
        .catch(() => {
          //console.log(error);
          this.errorAPI = true;
        });
        window.scrollTo(0,0);
    },
    state_fr: function(state) {
      if (this.volant && state === 'absent') {
        return this.states['excused']
      }
      return this.states[state]
    },
    getClass: function(state){
    return {
      'fa-spinner': state === 'open',
      'fa-check': state === 'done' || state === 'excused',
      'fa-times': state === 'cancel' || state === 'absent',
      'fa-users': state === 'replacing' || state === 'replaced',
      'fa-question': state === 'waiting'
    }
  }
  },

  computed: {
     futureServices: function() {
       const futures = this.services.filter(s => {
         return Date.now() < Date.parse(s.dateBegin);
      })
      return futures.sort(function(a,b) {
        return Date.parse(a.dateBegin) - Date.parse(b.dateBegin);
      })
    },
    pastServices: function() {
      return this.services.filter(s => {
        return Date.now() > Date.parse(s.dateBegin);
      })
    },
    ...authComputed
  }
};

(function() {
  document.getElementById("select").selectize();
});
</script>



<style lang="css" scoped>
.is-title {
  text-transform: capitalize;
}

.subtitle:not(:last-child),
.title:not(:last-child) {
  margin-bottom: 1.5rem;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.125;
}

.level-item .subtitle,
.level-item .title {
  margin-bottom: 0;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle {
  color: #2e2e2e;
  font-weight: 300;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
}

.subtitle strong,
.title strong {
  font-weight: 500;
}

.level-item {
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-preferred-size: auto; */
  flex-basis: auto;
  /* -ms-flex-positive: 0; */
  flex-grow: 0;
  /* -ms-flex-negative: 0; */
  flex-shrink: 0;
  /* -ms-flex-pack: center; */
  justify-content: center;
}

.breadcrumb {
  --separator: "\2044";
  list-style: none;
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-pack: end; */
  justify-content: flex-end;
}

.level-left .level-item:not(:last-child),
.level-right .level-item:not(:last-child) {
  margin-right: 0.75rem;
}

.is-vertical-center {
  text-align: center;
  padding-top: 10px;
}

.inscription-title {
  color: #363636;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  padding-bottom: 10px;
}

.inscription-button {
  background-color: #00d1b2;
  border-color: transparent;
  color: white;
  justify-content: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 600;
  font-size: 1rem;
}

.recap {
  padding: 30px;
}

input {
  display: none;
  visibility: hidden;
}

label {
  display: block;
  padding: 0.5em;
  text-align: center;
  color: #111;
}
label:hover {
  color: #666;
}
label::before {
  font-family: Consolas, monaco, monospace;
  font-weight: bold;
  font-size: 15px;
  content: "+";
  vertical-align: text-top;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 3px;
}

#expand {
  height: 0px;
  overflow: hidden;
  transition: height 1s;
  color: #fff;
}

#toggle:checked ~ #expand {
  height: 180px;
}
#toggle:checked ~ label::before {
  content: "-";
}

#searchFilters {
  padding: 0px;
}

.icon {
  color:#00d1b2;
}

.coll-format{
  width:45%;
}

.fa {
  color: #ff4200;
}
</style>
